import React from 'react';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import imageEB from '../../assets/EB_logo.png';

interface props {
  title: any;
  node: any;
  allAnswers: any;
}

const PDFContent: React.FC<props> = ({ node, allAnswers, title }) => {
  const currentDate = new Date().toLocaleDateString();

  Font.register({
    family: 'Raleway',
    fonts: [
      {
        src: '/fonts/Raleway/Raleway-Regular.ttf', // Regular
        fontWeight: 400,
      },
      {
        src: '/fonts/Raleway/Raleway-Italic.ttf', // Regular
        fontStyle: 'italic',
        fontWeight: 400,
      },
    ],
  });

  Font.register({
    family: 'Lora',
    fonts: [
      {
        src: '/fonts/Lora/Lora-Regular.ttf', // Lora Regular
        fontWeight: 400,
      },
    ],
  });

  Font.register({
    family: 'Caveat',
    fonts: [
      {
        src: '/fonts/Caveat/Caveat-Regular.ttf', // Lora Regular
        fontWeight: 400,
      },
    ],
  });

  Font.register({
    family: 'Quicksand',
    fonts: [
      {
        src: '/fonts/Quicksand/Quicksand-Regular.ttf', // Lora Regular
        fontWeight: 400,
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      padding: 35,
    },
    header: {
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderBottom: '0.08vw solid #a9a8af',
      paddingBottom: 10,
      marginBottom: 20,
      gap: 15,
    },
    title: {
      fontSize: 30,
      color: '#e88500',
      fontFamily: 'Raleway',
      fontWeight: 400,
    },
    question: {
      fontSize: 20,
      fontFamily: 'Raleway',
      fontStyle: 'italic',
      fontWeight: 400,
    },
    categories: {
      fontSize: 12,
      fontFamily: 'Raleway',
      fontWeight: 400,
    },
    answersNumber: {
      fontSize: 12,
      fontFamily: 'Raleway',
      fontWeight: 400,
    },
    date: {
      fontSize: 10,
      alignSelf: 'center',
    },
    footer: {
      backgroundColor: 'white',
      display: 'flex',
      height: '4vh',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderTop: '0.08vw solid #a9a8af',
      paddingTop: 10,
      marginTop: 20,
    },
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    card: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#e8e4fc',
      padding: 12,
      borderRadius: 10,
      marginBottom: 5,
    },
    cardTitle: {
      fontSize: 18,
      fontFamily: 'Lora',
      fontWeight: 400,
    },
    cardDescription: {
      fontSize: 11,
      marginTop: 10,
      marginBottom: 5,
      fontFamily: 'Quicksand',
      fontWeight: 400,
    },
    originTopic: {
      color: '#e88500',
      fontSize: 18,
    },
  });

  let question = '';
  let categs = '';
  if (node.depth === 1) {
    question = node.name;
  }
  if (node.depth === 2) {
    question = node.parent.name;
    categs = `${node.name}`;
  }
  if (node.depth === 3) {
    question = node.parent.parent.name;
    categs = `${node.parent.name} - ${node.name}`;
  }

  return (
    <Document>
      <Page style={styles.page}>
        {/* Cabeçalho */}
        <View style={styles.header}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.question}>{question}</Text>
          {categs !== '' && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontStyle: 'italic',
                  gap: 5,
                }}
              >
                <Text style={styles.categories}>{`${categs}`}</Text>
                <Text
                  style={styles.answersNumber}
                >{`[n = ${node.countAnswers}]`}</Text>
              </View>

              <Text style={styles.date}>{currentDate}</Text>
            </View>
          )}
          {categs === '' && <Text style={styles.date}>{currentDate}</Text>}
        </View>

        {/* Lista de Cards */}
        <View style={styles.cardContainer}>
          {allAnswers.map((ans: any, index: number) => (
            <View wrap={false} key={index} style={styles.card}>
              {ans.originTopic !== undefined && (
                <Text style={styles.originTopic}>{ans.originTopic}</Text>
              )}
              <Text style={styles.cardDescription}>{ans.answer}</Text>
            </View>
          ))}
        </View>

        {/* Rodapé */}
        <View style={styles.footer} fixed>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
              gap: 3,
            }}
          >
            <Image
              src={imageEB}
              style={{ width: 100, height: 35, alignSelf: 'center' }}
            />
          </View>
          <View style={{ display: 'flex', alignSelf: 'flex-end' }}>
            <Text
              style={{ fontSize: 7 }}
              render={({ pageNumber, totalPages }) =>
                `page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFContent;
